import { EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import moment from "moment";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import StandardProposalSearchPanel from "./Left/StandardProposalSearchPanel";
import StandardProposalRightPanel from "./Right/StandardProposalRightPanel";
import StandardProposalRejectReasonModal from "./StandardProposalRejectReasonModal";
import StandardProposalTitleBar from "./StandardProposalTitleBar";

const StandardProposalMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const {searchStatus,searchCriteria,isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail} = standardProposalState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    standardProposalVM.searchAllStandardProposal(),
                    standardProposalVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
                if(searchStatus === ""){
                    let newSearchCriteria = null;
                    if(isBackFromDetail){
                        newSearchCriteria = {...searchCriteria};
                    }else{
                        newSearchCriteria = {...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA} 
                        newSearchCriteria = { ...newSearchCriteria, 
                            effectiveDateFrom: moment().subtract(2, "years").startOf("year").toDate(),
                            effectiveDateTo: moment().endOf('year').endOf('day').toDate(), 
                        };
                    }
                    const standardResults = await Promise.allSettled([
                        standardProposalVM.searchStandardProposal(newSearchCriteria),
                    ]);
                    standardResults.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {
    
                        } 
                    })
                }else{
                    const groupButtonResults = await Promise.allSettled([
                        standardProposalVM.onGroupButtonClick(searchStatus),
                    ]);
                    groupButtonResults.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {
    
                        } 
                    })
                }
            } catch(error) {
                setIsLoading(false);
            }
        }
        isAllowAutoSearch && initialScreen().then(() => {  
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isAllowAutoSearch, isBackFromDetail, searchCriteria, searchStatus, standardProposalVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <StandardProposalTitleBar/>
           {standardProposalState.isRejecting && <Loader Indicator="Spinner" size="Medium" /> }
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<StandardProposalSearchPanel/>}
                rightChildren={<StandardProposalRightPanel/>} 
                />
            <div className='im-charge-data-search-confirm-modal-container'>
                { <StandardProposalRejectReasonModal/> }
            </div>
        </div>
    </>
}

export default memo(StandardProposalMaintenance);