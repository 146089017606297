import { approvalProcessStatusDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const StandardProposalSearchForm = () => {    
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Search;
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const searchCriteria = standardProposalState.searchCriteria;


    const memoProposalNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO}
                type="text"
                value={searchCriteria.proposalNo || ''}
                onChange={(e: any) => standardProposalVM.onSearchInputTextChange(e, 'proposalNo')}/>
        </div>
    , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO, searchCriteria.proposalNo, standardProposalVM])


    const memoCompanyCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.companyCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => standardProposalVM.onMultipleDropdownChange(e, 'companyCodeList')}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}/>
        </div>
    , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, searchCriteria.companyCodeList, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])
    
    const memoOperatingTml = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={STANDARD_PROPOSAL_CONSTANT.OPERATING_TML}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.operatingTmlList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => standardProposalVM.onMultipleDropdownChange(e, 'operatingTmlList')}
                options={standardProposalState.dynamicOptions.operatingTmlDropdownOptions}/>
        </div>
    , [STANDARD_PROPOSAL_CONSTANT.OPERATING_TML, searchCriteria.operatingTmlList, standardProposalState.dynamicOptions.operatingTmlDropdownOptions, standardProposalVM])
    
    const memoStatus = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={STANDARD_PROPOSAL_CONSTANT.STATUS}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.statusList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => standardProposalVM.onMultipleDropdownChange(e, 'statusList')}
                options={approvalProcessStatusDropdownOption}/>
        </div>
    , [STANDARD_PROPOSAL_CONSTANT.STATUS, searchCriteria.statusList, standardProposalVM])
    

    const memoEffectiveDateFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE_RANGE}
                    width='390px'
                    fieldNames={{startField:"effectiveDateFrom", endField:"effectiveDateTo"}}
                    dateRange={{startDate:searchCriteria.effectiveDateFrom, endDate:searchCriteria.effectiveDateTo}}
                    onDatesChange={standardProposalVM.onDateRangeChange}
                />                
            </div>
        , [STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE_RANGE, searchCriteria.effectiveDateFrom, searchCriteria.effectiveDateTo, standardProposalVM.onDateRangeChange])
    
    return <>        
        
        <CriteriaItemContainer>
            {standardProposalState.enabledSearchCriteria.companyCode ? memoCompanyCode : null}
            {standardProposalState.enabledSearchCriteria.operatingTml ? memoOperatingTml : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {standardProposalState.enabledSearchCriteria.proposalNo ? memoProposalNo : null}
            {standardProposalState.enabledSearchCriteria.status ? memoStatus : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {standardProposalState.enabledSearchCriteria.effectiveDateRange ? memoEffectiveDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}
export default memo(StandardProposalSearchForm);