import { decimalPlaceDropdownOption, proposalByDropdownOption, roundMethodDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const UpdatePercentageAndProposalNoPanel: React.FC = () => {

    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const messageBarVM = useMessageBarVM();
    const { isEditPercentage, isEditProposalNo, isEditNewProposalNo, copyToEditPercentage, copyToEditProposalNo, copyToEditNewProposal } = standardProposalState;

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        standardProposalVM.onCopyPercentageFieldChange(fieldName, fieldValue);
    }, [standardProposalVM]);

    const handClose = useCallback(() => {
        standardProposalVM.onTariffItemCopyEditCloseClick();
    }, [standardProposalVM]);

    const handSave = useCallback(() => {
        if (isEditPercentage) {
            standardProposalVM.onCopyEditPercentageSaveClick();
        }
        if (isEditProposalNo) {
            standardProposalVM.onCopyEditProposalNoSaveClick();
        }
        if (isEditNewProposalNo) {
            if (!copyToEditNewProposal.effectiveDate) {
                messageBarVM.showError("Effective Date is mandatory.");
                return;
            }
            if (!copyToEditNewProposal.expiryDate) {
                messageBarVM.showError("Expiry Date is mandatory.");
                return;
            }
            if (new Date(copyToEditNewProposal.expiryDate) <= new Date(copyToEditNewProposal.effectiveDate)) {
                messageBarVM.showError("The Expiry Date must be greater than the Effective Date.");
                return;
            }
            if (!copyToEditNewProposal.currencyCode) {
                messageBarVM.showError("Currency is mandatory.");
                return;
            }
            if (!copyToEditNewProposal.proposalCreatedByMethod) {
                messageBarVM.showError("Proposal By is mandatory.");
                return;
            }
            standardProposalVM.onCopyEditNewProposalNoSaveClick();
        }
    }, [messageBarVM, copyToEditNewProposal, isEditNewProposalNo, isEditPercentage, isEditProposalNo, standardProposalVM]);

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={copyToEditPercentage.tariffType || ''}
                fieldValue={copyToEditPercentage.tariffType}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyPercentageFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE, copyToEditPercentage.tariffType, standardProposalState.dynamicOptions.tariffTypeDropdownOptions, standardProposalVM])

    const memoChargePercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px", marginLeft: "20px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.CHARGE_PERCENTAGE}
                maxLength={60}
                fieldName={"chargePercentage"}
                value={copyToEditPercentage.chargePercentage ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CHARGE_PERCENTAGE, copyToEditPercentage, onNumberFieldChange])

    const memoRoundMethod = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={copyToEditPercentage.roundMethod || ''}
                fieldValue={copyToEditPercentage.roundMethod}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.ROUND_METHOD}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'roundMethod'}
                maxLength={60}
                options={roundMethodDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyPercentageFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.ROUND_METHOD, copyToEditPercentage, standardProposalVM])

    const memoDecimalPlace = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={copyToEditPercentage.decimalPlace || ''}
                fieldValue={copyToEditPercentage.decimalPlace}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.DECIMAL_PLACE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'decimalPlace'}
                maxLength={60}
                options={decimalPlaceDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyPercentageFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.DECIMAL_PLACE, copyToEditPercentage, standardProposalVM])


    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={copyToEditProposalNo.companyCode || ''}
                fieldValue={copyToEditProposalNo?.companyCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, copyToEditProposalNo.companyCode, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={copyToEditProposalNo.proposalNo || ''}
                fieldValue={copyToEditProposalNo.proposalNo}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalNo'}
                maxLength={60}
                requiredFieldList={[]}
                options={standardProposalState.dynamicOptions.companyCodeProposalNoDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO, copyToEditProposalNo.proposalNo, standardProposalState.dynamicOptions.companyCodeProposalNoDropdownOptions, standardProposalVM])

    const memoNewCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={copyToEditNewProposal?.companyCode || ''}
                fieldValue={copyToEditNewProposal?.companyCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyNewProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, copyToEditNewProposal?.companyCode, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                width="185px"
                date={copyToEditNewProposal.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={standardProposalVM.onCopyNewProposalNoFieldChange} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE, copyToEditNewProposal.effectiveDate, standardProposalVM.onCopyNewProposalNoFieldChange])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE}
                width="185px"
                date={copyToEditNewProposal.expiryDate}
                fieldName="expiryDate"
                errorMessage={""}
                onDateChange={standardProposalVM.onCopyNewProposalNoFieldChange} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE, copyToEditNewProposal.expiryDate, standardProposalVM.onCopyNewProposalNoFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={copyToEditNewProposal?.currencyCode || ''}
                fieldValue={copyToEditNewProposal?.currencyCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.CURRENCY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.currencyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyNewProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CURRENCY, copyToEditNewProposal?.currencyCode, standardProposalState.dynamicOptions.currencyCodeDropdownOptions, standardProposalVM])

    const memoProposalBy = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={copyToEditNewProposal.proposalCreatedByMethod || ''}
                fieldValue={copyToEditNewProposal?.proposalCreatedByMethod}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalCreatedByMethod'}
                maxLength={60}
                options={proposalByDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyNewProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY, copyToEditNewProposal.proposalCreatedByMethod, standardProposalVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' id="std-proposal-edit-remarks" style={{ width: "420px", marginBottom: "10px", marginLeft: "20px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={copyToEditNewProposal?.remarks || ''}
                fieldValue={copyToEditNewProposal.remarks}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'remarks'}
                maxLength={200}
                rows={1}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyNewProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.REMARKS, copyToEditNewProposal.remarks, standardProposalVM])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "10px", marginLeft: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={copyToEditNewProposal.operatingTml || ''}
                fieldValue={copyToEditNewProposal.operatingTml}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.OPERATING_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.operatingTmlDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onCopyNewProposalNoFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.OPERATING_TML, copyToEditNewProposal.operatingTml, standardProposalState.dynamicOptions.operatingTmlDropdownOptions, standardProposalVM])


    return <>
        <div className={'flex-row'} style={{ overflow: "visible", zIndex: "100" }}>
            <div className={'flex-row-item flex-row-item-full-width'} >
                <Sidebarheader>
                    <SidebarTitle></SidebarTitle>
                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handClose} />
                        <IconButton
                            fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handSave} />
                    </SidebarActionCross>
                </Sidebarheader>
            </div>


            {isEditPercentage && <>
                <CriteriaItemContainer>
                    {memoTariffType}
                    {memoChargePercentage}
                    {/* </CriteriaItemContainer>

                <CriteriaItemContainer> */}
                    {memoRoundMethod}
                    {memoDecimalPlace}
                </CriteriaItemContainer></>}

            {isEditProposalNo && <>
                <CriteriaItemContainer>
                    {memoCompanyCode}
                    {memoProposalNo}
                </CriteriaItemContainer></>}

            {isEditNewProposalNo && <>
                <CriteriaItemContainer>
                    {memoNewCompanyCode}
                    {memoEffectiveDate}
                    {memoExpiryDate}
                    {memoCurrency}
                    {memoProposalBy}
                    {memoOperatingTml}
                    {memoRemarks}
                </CriteriaItemContainer></>}
        </div>
    </>;
}

export default memo(UpdatePercentageAndProposalNoPanel);
