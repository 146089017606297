import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import StandardProposalDetailContMain from "presentation/view/container/StandardProposal/StandardProposalDetailContMain";
import StandardProposalMaintenance from "./StandardProposalMaintenance";

export const StandardProposalMDView: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
     const isShowDetail = standardProposalState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <StandardProposalMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><StandardProposalDetailContMain/> </div>
    } 

    </>
}