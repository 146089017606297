import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StandardProposalItemRepoImpl } from "domain/repository/StandardProposal/StandardProposalItemRepoImpl";
import { StandardProposalRepoImpl } from "domain/repository/StandardProposal/StandardProposalRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { StandardProposalVM } from "presentation/viewModel/StandardProposal/StandardProposalVM";
import { useMemo } from "react";

export const useStandardProposalVM = () => {
    const [, setStandardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useMemo(() =>
        StandardProposalVM({
            dispatch: [setStandardProposalState],
            companyRepo: CompanyRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            standardProposalRepo: StandardProposalRepoImpl(),
            standardProposalItemRepo: StandardProposalItemRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            tariffTypeRepo:TariffTypeRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            shiftRepo: ShiftRepoImpl(),
        }), [setStandardProposalState])

    return standardProposalVM
}

