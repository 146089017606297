import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useCallback, useMemo } from "react";
import { HPHButton, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StandardProposalTariffItemTablePanel from "./StandardProposalTariffItemTablePanel";
import TariffItemCopyRightPanel from "./TariffItemCopyRightPanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";

const TariffItemRightPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const messageBarVM = useMessageBarVM();
    const { isShowCopyPanel, deleteItemList, isShowTariffItemTierPanel, standardProposalItemList, searchCriteria, searchStatus, tariffCodeIds, isUpdateOrAdd, currentTariffItem, isAddIs, isAddTier, isEditIs, isEditTier, isAddTariffItem } = standardProposalState;

    const memoTariffItemCopyRightPanel = useMemo(() => {
        return <TariffItemCopyRightPanel />
    }, [])

    const memoTariffItemTable = useMemo(() => {
        return <StandardProposalTariffItemTablePanel />
    }, [])
    const memoTariffItemTierTable = useMemo(() => {
        return <TariffItemTierTablePanel />
    }, [])

    const isDisable = () => {
        if (!(isAddIs || isAddTier || isEditIs || isEditTier) && (isAddTariffItem || isUpdateOrAdd)) return false
        return true;
    }

    const handleSave = useCallback(async () => {
        if (isAddTariffItem) {
            if (!currentTariffItem.tariffType) {
                messageBarVM.showError("Tariff Type is mandatory.");
                return;
            }
            if (!currentTariffItem.tariffCode) {
                messageBarVM.showError("Tariff Code is mandatory.");
                return;
            }
        }
        if (standardProposalItemList && standardProposalItemList.length > 0) {
            if (!currentTariffItem.key && standardProposalItemList.some(item =>
                item.tariffType === currentTariffItem.tariffType &&
                item.tariffCode === currentTariffItem.tariffCode &&
                (item.forwarderCode ? item.forwarderCode : "") === currentTariffItem.forwarderCode
            )) {
                messageBarVM.showError("Duplicate record found, please check the key: Tariff Type, Tariff Code, Forwarder Code.");
                return;
            }
        }
        if (isUpdateOrAdd) {
            // if(currentTariffItem.tierList && currentTariffItem.tierList.length === 0){
            //     messageBarVM.showError("Must add one tariff item tier at least.");
            //     return;
            // }
            standardProposalVM.onShowLoading();
            await standardProposalVM.onSaveTariffItem(standardProposalState.currentSelectedRow, currentTariffItem, standardProposalItemList, tariffCodeIds, deleteItemList).then(async (data) => {
                if (data.code !== "200") {
                    messageBarVM.showError(data.data);
                    standardProposalVM.onHideLoading();
                    return;
                } else {
                    if (searchStatus !== "") {
                        await standardProposalVM.onGroupButtonClick(searchStatus);
                    } else {
                        await standardProposalVM.searchStandardProposal(searchCriteria);
                    }
                }
            });
        }
        standardProposalVM.onHideLoading();

    }, [isAddTariffItem, standardProposalItemList, isUpdateOrAdd, standardProposalVM, currentTariffItem, messageBarVM, standardProposalState.currentSelectedRow, tariffCodeIds, deleteItemList, searchStatus, searchCriteria]);


    return <>
        <div className={`main-comp-wrapper${(isShowTariffItemTierPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={isShowTariffItemTierPanel ? "40%" : "100%"}
                    rightSectionWidth={isShowTariffItemTierPanel ? "60%" : "0%"}
                    leftChildren={memoTariffItemTable}
                    rightChildren={isShowTariffItemTierPanel ? (isShowCopyPanel ? memoTariffItemCopyRightPanel : memoTariffItemTierTable) : memoTariffItemTierTable}
                />
            </div>

            <div style={{ paddingRight: '20px', visibility: !isDisable() ? 'visible' : 'hidden', height: '100%', maxHeight: '15%' }} >
                <SidebarActionBar>
                    {<HPHButton disabled={isDisable()} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />}
                    {/* {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />} */}
                </SidebarActionBar>
            </div>
        </div>
    </>
}

export default memo(TariffItemRightPanel);